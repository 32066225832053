import { DataGridControl } from 'o365.controls.DataGrid.ts';

declare module 'o365.controls.DataGrid.ts' {
    interface DataGridControl {
        layoutManagber: LayoutManager;
    }
}

Object.defineProperties(DataGridControl.prototype, {
    'layoutManager': {
        get() {
            if (this.dataObject == null) {
                return null;
            } else {
                if (this._layoutManagber == null) {
                    this._layoutManagber = new LayoutManager(this);
                }
                return this._layoutManagber;
            }
        }
    },
});

/**
 * Helper class to initialize on-demand modules from layouts.
 * Used to startup extensinos such as summaryData and nodeData
 */
export class LayoutManager {
    private _dataGridControl: DataGridControl;

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    initialize() {
        if (this._dataGridControl.dataObject == null) { return; }
        this.initExtensionsFromLayout();
        return this._dataGridControl.dataObject.on('LayoutApplied', () => {
            this.initExtensionsFromLayout();
        })
    }

    initExtensionsFromLayout() {
        const dataObject = this._dataGridControl.dataObject;
        if (dataObject == null) { return; }
        if (!dataObject.layoutManager?.hasActiveLayout) { return; }
        const activeLayout = dataObject.layoutManager.activeLayout!;
        
        if (activeLayout.layoutRecord['summaryData'] != null) {
            import('o365.controls.DataGrid.extensions.SummaryData.ts').then(() => {
                this._dataGridControl.summaryData;
            });
        }
    }
}